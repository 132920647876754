import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import theme from '@/style';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import RightMenusService from './right-menus-service';
import RightMenusTop from './right-menus-top';
import {getImgs} from '@/utils';
import {SafeAny} from '@/types';
import globalStore from '@/services/global.state';

export interface RightMenusProps {
  hasTop?: boolean;
  onTop?: () => void;
}

const RightMenus: React.FC<RightMenusProps> = ({hasTop, onTop}) => {
  const {calcActualSize} = useScreenSize();
  const [imgs, setImgs] = React.useState({} as {[key: string]: string});
  const rightMenusStyle = StyleSheet.create({
    main: {
      right: calcActualSize(22),
      bottom: calcActualSize(24),
    },
  });

  React.useEffect(() => {
    getImgs((r: SafeAny) =>
      setImgs(
        r.reduce((pre: SafeAny, cur: SafeAny) => {
          if (cur.moduleType === 'IM_APP') {
            globalStore.imIcon = cur.defaultImgUrl;
          }
          (pre as SafeAny)[cur.moduleType] = cur.defaultImgUrl;
          return pre;
        }, {}),
      ),
    );
  }, []);

  return (
    <View
      style={[
        theme.position.abs,
        rightMenusStyle.main,
        theme.flex.col,
        {
          gap: theme.paddingSize.l,
        },
      ]}>
      {!!imgs.CUSTOMER_SERVICE && (
        <RightMenusService img={imgs.CUSTOMER_SERVICE} />
      )}
      {hasTop ? (
        <View>
          <RightMenusTop onClick={onTop} />
        </View>
      ) : null}
    </View>
  );
};

export default RightMenus;
