import Text from '@basicComponents/text';
import React from 'react';
import {View, Image} from 'react-native';
import theme from '@style';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import globalStore from '@services/global.state';
import Tag from '@basicComponents/tag';
import {goTo, useResponsiveDimensions} from '@/utils';
import {useLanguageModal} from '@/components/business/language';
import {useTranslation} from 'react-i18next';
import {languagesMap} from '@/i18n';
import {
  CasinoSvg,
  LiveSvg,
  LotterySvg,
  PromotionsSvg,
  ScratchSvg,
} from '@/components/svgs';
import homeStore from '../home.refresh.service';
import {StyleSheet} from 'react-native';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import SportsSvg from '@/components/svgs/basic/sports';

const HomeMenu = (props: {
  setMenuOpen: (bool: boolean) => void;
  unreadCount?: number;
}) => {
  const {setMenuOpen, unreadCount} = props;
  const {height: screenHeight} = useResponsiveDimensions();
  const {i18n} = useTranslation();
  const {renderModal, show} = useLanguageModal();
  const {calcActualSize} = useScreenSize();

  const styles = StyleSheet.create({
    itemIcon: {
      height: calcActualSize(24),
      width: calcActualSize(24),
    },
    down: {
      height: calcActualSize(12),
      width: calcActualSize(12),
    },
  });
  return (
    <View
      style={[
        theme.fill.fillW,
        theme.flex.col,
        theme.padding.l,
        {
          height: screenHeight,
        },
      ]}>
      <View
        style={[
          theme.padding.tbl,
          theme.flex.flex,
          theme.flex.row,
          theme.flex.centerByCol,
        ]}>
        <Image
          style={[theme.icon.xl, theme.borderRadius.m, theme.margin.rights]}
          source={require('@assets/imgs/home/logo.png')}
        />
        <Text fontSize={15} calc fontFamily="fontDin" fontWeight="900">
          BHAU
        </Text>
        <View
          style={[theme.flex.flex1, theme.flex.row, theme.flex.centerByRow]}>
          <View style={[theme.flex.flex1]} />
          <NativeTouchableOpacity onPress={() => setMenuOpen(false)}>
            <Image
              style={[styles.itemIcon]}
              source={require('@assets/icons/close.webp')}
            />
          </NativeTouchableOpacity>
        </View>
      </View>
      <NativeTouchableOpacity
        onPress={() => {
          setMenuOpen(false);
          if (globalStore.token) {
            goTo('Invitation');
          } else {
            goTo('Login');
          }
        }}>
        <Image
          style={[
            theme.margin.tbxxl,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              width: 256,
              height: 62,
            },
          ]}
          source={require('@assets/imgs/home/menu-banner.webp')}
          resizeMode="contain"
        />
      </NativeTouchableOpacity>
      {[
        {
          icon: LotterySvg,
          label: 'lottery',
          onPress: () => {
            homeStore.changeTab.next(0);
          },
        },
        {
          icon: ScratchSvg,
          label: 'scratchOff',
          onPress: () => {
            homeStore.changeTab.next(1);
          },
        },
        {
          icon: LiveSvg,
          label: 'liveCasino',
          onPress: () => {
            homeStore.changeTab.next(2);
          },
        },
        {
          icon: CasinoSvg,
          label: 'casino',
          onPress: () => {
            homeStore.changeTab.next(3);
          },
        },
        {
          icon: SportsSvg,
          label: 'sports',
          onPress: () => {
            if (globalStore.token) {
              globalStore.openSports.next('open');
            } else {
              goTo('Login');
            }
          },
        },
        {
          icon: PromotionsSvg,
          label: 'promotions',
          navTo: 'Promotions',
        },
      ].map((v, i) => (
        <NativeTouchableOpacity
          key={i}
          onPress={() => {
            if (v.navTo) {
              setTimeout(() => {
                setMenuOpen(false);
              }, 16);
              goTo(v.navTo);
            } else if (v.onPress) {
              setTimeout(() => {
                setMenuOpen(false);
              }, 16);
              v.onPress();
            } else {
              globalStore.globalTotal.next({
                type: 'warning',
                message: i18n.t('warning.unopen'),
              });
            }
          }}
          style={[
            theme.padding.l,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              marginBottom: theme.paddingSize.xxs / 2,
              backgroundColor: '#F6F7FB',
            },
          ]}>
          <View style={[theme.flex.row, theme.flex.centerByCol]}>
            <v.icon color={theme.fontColor.second} />
            <Text
              fontSize={theme.fontSize.m}
              calc
              fontWeight="500"
              style={[theme.margin.leftl]}>
              {i18n.t('home.tab.' + v.label)}
            </Text>
          </View>
        </NativeTouchableOpacity>
      ))}
      {/* 消息模块 */}
      <NativeTouchableOpacity
        onPress={() => {
          goTo('Notification');
          setMenuOpen(false);
        }}
        style={[
          // eslint-disable-next-line react-native/no-inline-styles
          {
            backgroundColor: '#F6F7FB',
          },
          theme.padding.l,
          theme.margin.topxxl,
        ]}>
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          <Image
            style={[styles.itemIcon, theme.margin.rightl]}
            source={require('@assets/icons/home/notifications.webp')}
          />
          <Text fontWeight="500" calc fontSize={theme.fontSize.m}>
            {i18n.t('me.bottom.notify')}
          </Text>
          <View style={theme.flex.flex1} />
          {unreadCount != null && unreadCount > 0 && (
            <Tag content={unreadCount} />
          )}
        </View>
      </NativeTouchableOpacity>
      <NativeTouchableOpacity
        style={[
          // eslint-disable-next-line react-native/no-inline-styles
          {
            backgroundColor: '#F6F7FB',
          },
          theme.padding.l,
          theme.margin.topxxl,
        ]}
        onPress={() => show()}>
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          <Image
            style={[styles.itemIcon, theme.margin.rightl]}
            source={require('@assets/icons/home/earth.webp')}
          />
          <Text fontWeight="500" calc fontSize={theme.fontSize.m}>
            {languagesMap[globalStore.lang]}
          </Text>
          <View style={theme.flex.flex1} />
          <Image
            style={[styles.down]}
            source={require('@assets/icons/down.webp')}
          />
        </View>
      </NativeTouchableOpacity>
      {renderModal}
    </View>
  );
};

export default HomeMenu;
