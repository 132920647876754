import React from 'react';
import {KeralaListItem} from '../../home.type';
import globalStore from '@/services/global.state';
import {goTo, toPriceStr, useResponsiveDimensions} from '@/utils';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import {LazyImageBackground} from '@/components/basic/image';
import theme from '@/style';
import {ImageBackground, View, Image} from 'react-native';
import Text from '@/components/basic/text';
import {KERALA_GAME_ICON, KERALA_IMAGES, KERALA_IMAGES_LOGO} from '../../games';
import HomeFloorBox from '../home-floor-box';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';

interface KeralaProps {
  list: KeralaListItem[];
  serverTime: number;
}

const isClosed = (item: KeralaListItem, serverTime: number) => {
  const seconds = dayjs(item.drawDate).diff(dayjs(serverTime), 'second');
  if (seconds <= 0) {
    return true;
  }
  return false;
};

const HomeLotteryKerala = ({list = [], serverTime = 0}: KeralaProps) => {
  const i18n = useTranslation();
  const {width} = useResponsiveDimensions();

  const [targetList, setTargetList] = React.useState<
    (KeralaListItem & {sellAmount: string; isClosed: boolean})[][]
  >([]);

  React.useEffect(() => {
    const keralaList = list.map(v => ({
      ...v,
      sellAmount: toPriceStr(v.sellMoney || v.lotteryPrice),
      result: v.lastDrawnCode ? v.lastDrawnCode.split('') : Array(8).fill('-'),
      isClosed: isClosed(v, serverTime),
      backImg:
        KERALA_IMAGES_LOGO[v.lotteryType] ||
        'https://download.bhaulotterys.com/kerala/order/bumper.webp', // 这个用于传给h5做logo
      backgroundImage:
        (globalStore.isAndroid
          ? KERALA_GAME_ICON[v.lotteryType]
          : KERALA_IMAGES[v.lotteryType]) ||
        require('../../games/kerala/bumper.webp'), // 这个用于首页显示 // 差一个bumper
    }));
    if (keralaList.length * 140 > width * 1.5) {
      const halfNum = Math.ceil(keralaList.length / 2);
      const arr1 = keralaList;
      const arr2 = keralaList.splice(halfNum);
      setTargetList(arr1.map((v, i) => [v, arr2[i]]));
    } else {
      setTargetList(keralaList.map(v => [v]));
    }
  }, [list, serverTime, width]);
  const imageSize = (theme.imageSize.m / 375) * width;
  return (
    <HomeFloorBox
      title={i18n.t('home.kerala.title')}
      list={targetList}
      color="#EDD9D9"
      renderItem={(arr, index) => {
        return (
          <View key={index}>
            {arr?.map((item, itemIndex) => {
              return item === null || item === undefined ? (
                <View key={index + '' + itemIndex} />
              ) : (
                <NativeTouchableOpacity
                  style={[
                    {
                      height: (140 / 375) * width,
                      width: (159 / 375) * width,
                    },
                    theme.background.palegrey,
                    theme.borderRadius.m,
                    theme.overflow.hidden,
                    theme.position.rel,
                    itemIndex !== 0 && theme.margin.topxs,
                    index !== 0 && theme.margin.leftxs,
                  ]}
                  disabled={!item}
                  onPress={() => {
                    if (!serverTime) {
                      return globalStore.globalWaringTotal(
                        i18n.t('warning.initializin'),
                      );
                    }
                    if (item.isClosed) {
                      globalStore.globalWaringTotal(
                        i18n.t('home.tip.closed', {
                          name: `${
                            (item.lotteryType?.indexOf('BUMPER') > -1
                              ? 'festival'
                              : 'weekly') + ' lottery'
                          } ${item.issueNo}`,
                        }),
                      );
                      return;
                    }
                    goTo('GameWebView', {
                      type: 'kerala',
                      params: `data=${JSON.stringify(item)}`,
                    });
                  }}
                  key={index + '' + itemIndex}>
                  <LazyImageBackground
                    height={((140 - 34) / 375) * width}
                    width={(159 / 375) * width}
                    imageUrl={item.backgroundImage}
                    style={[
                      theme.padding.tbl,
                      theme.padding.lrs,
                      theme.flex.between,
                    ]}>
                    <View style={[theme.fill.fill, theme.flex.between]}>
                      <View>
                        <View
                          style={[
                            theme.flex.row,
                            theme.flex.centerByCol,
                            theme.flex.between,
                          ]}>
                          <Text
                            calc
                            blod
                            fontSize={10}
                            color={theme.basicColor.white}>
                            {item.lotteryType}
                          </Text>
                          <Text
                            calc
                            blod
                            fontSize={10}
                            color={theme.basicColor.white}>
                            {item.issueNo}
                          </Text>
                        </View>
                        <View style={[theme.flex.row, theme.flex.centerByCol]}>
                          <View>
                            <Text
                              calc
                              style={[
                                // eslint-disable-next-line react-native/no-inline-styles
                                {
                                  fontStyle: 'italic',
                                  fontWeight: '700',
                                  lineHeight: globalStore.isWeb ? 12 : 8,
                                  marginRight: 2,
                                },
                              ]}
                              textAlign="left"
                              fontSize={6}
                              color={'#FFE939'}>
                              WIN
                            </Text>
                            <Text
                              calc
                              style={[
                                // eslint-disable-next-line react-native/no-inline-styles
                                {
                                  fontStyle: 'italic',
                                  fontWeight: '700',
                                  lineHeight: globalStore.isWeb ? 12 : 8,
                                  marginRight: 2,
                                },
                              ]}
                              textAlign="left"
                              fontSize={6}
                              color={'#FFE939'}>
                              PRIZE
                            </Text>
                          </View>
                          <Text
                            calc
                            size="medium"
                            fontFamily="fontDinBold"
                            color={theme.basicColor.white}>
                            {item.lotteryMoney > 99
                              ? item.lotteryMoney / 100 + ' Crore'
                              : item.lotteryMoney + ' Lakhs'}
                          </Text>
                        </View>
                      </View>
                      <ImageBackground
                        source={require('@assets/imgs/home/digit-result-bg.webp')}
                        resizeMode="stretch"
                        style={[
                          theme.padding.tops,
                          theme.flex.center,
                          theme.padding.btmxs,
                          theme.padding.lrl,
                        ]}>
                        <Text calc fontSize={8} color={theme.basicColor.white}>
                          {i18n.t('home.kerala.lastResult')}
                        </Text>
                        <View
                          style={[
                            theme.margin.topxxs,
                            theme.flex.row,
                            theme.flex.centerByCol,
                            theme.flex.between,
                            theme.fill.fillW,
                            // eslint-disable-next-line react-native/no-inline-styles
                            {
                              maxWidth: 148,
                            },
                          ]}>
                          {item.result.map((v: any, i: number) => {
                            return (
                              <View
                                style={[
                                  i === 1 && {
                                    marginRight: theme.paddingSize.xxs / 2,
                                  },
                                  theme.borderRadius.xxs,
                                  // eslint-disable-next-line react-native/no-inline-styles
                                  {
                                    backgroundColor:
                                      i < 2 ? '#BC9200' : '#46360B',
                                    height: theme.fontSize.l,
                                    width: theme.fontSize.m,
                                  },
                                  theme.flex.center,
                                ]}
                                key={i}>
                                <Text
                                  calc
                                  blod
                                  color={theme.basicColor.white}
                                  style={[
                                    v !== '*' && {
                                      lineHeight: theme.fontSize.l,
                                    },
                                  ]}>
                                  {v}
                                </Text>
                              </View>
                            );
                          })}
                        </View>
                      </ImageBackground>
                    </View>
                  </LazyImageBackground>
                  <View
                    style={[
                      theme.flex.flex1,
                      theme.flex.row,
                      theme.flex.centerByCol,
                      theme.flex.between,
                      theme.padding.lrs,
                      theme.background.white,
                    ]}>
                    <View>
                      <Text
                        accent
                        style={[
                          // eslint-disable-next-line react-native/no-inline-styles
                          {
                            lineHeight: 12,
                          },
                        ]}
                        fontSize={10}>
                        {i18n.t('home.digit.price')}:
                      </Text>
                      <Text blod color={theme.basicColor.dark}>
                        {item.sellAmount}
                      </Text>
                    </View>
                    <View
                      style={[
                        theme.padding.tbxxs,
                        theme.padding.lrl,
                        theme.borderRadius.xl,
                        theme.border.secAccent,
                      ]}>
                      <Text blod style={[{lineHeight: theme.fontSize.m}]}>
                        Play
                      </Text>
                    </View>
                  </View>
                  {item.isClosed && (
                    <View
                      style={[
                        theme.fill.fill,
                        theme.position.abs,
                        {
                          backgroundColor: theme.basicColor.white + 'fff49',
                        },
                      ]}>
                      <Image
                        source={require('@assets/icons/home/closed-gray.webp')}
                        style={[
                          theme.position.abs,
                          {
                            right: theme.paddingSize.s,
                            top: theme.paddingSize.s,
                            width: imageSize,
                            height: imageSize,
                          },
                        ]}
                      />
                    </View>
                  )}
                </NativeTouchableOpacity>
              );
            })}
          </View>
        );
      }}
    />
  );
};

export default React.memo(HomeLotteryKerala);
