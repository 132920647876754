import React from 'react';
import {DigitListItem} from '../../home.type';
import globalStore from '@/services/global.state';
import {
  formatDate,
  goTo,
  saveDigitType,
  toPriceStr,
  useResponsiveDimensions,
} from '@/utils';
import {useTranslation} from 'react-i18next';
import {View, Image} from 'react-native';
import theme from '@/style';
import LazyImage from '@/components/basic/image';
import Text from '@/components/basic/text';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import HomeFloorBox from '../home-floor-box';
import Price from '@/components/business/price';
import CountDown from '../count-down';
import dayjs from 'dayjs';
import {SafeAny} from '@/types';

const getTimer = (time: number, cycle: number) => {
  const date = formatDate(time, 'yyyy-MM-dd');
  if (date === 'Invalid Date') {
    return 0;
  }
  return (
    cycle * 60 -
    Math.round(
      (Math.abs(time - new Date(date).getTime()) % (cycle * 60000)) / 1000,
    )
  );
};

type HomeDigitDetail = DigitListItem & {
  sellAmount: string;
  result: string[];
};

interface DigitProps {
  list: DigitListItem[];
  serverTime?: number;
  currentTime?: number;
}

const HomeLotteryDigit = ({list = [], serverTime, currentTime}: DigitProps) => {
  const i18n = useTranslation();
  const {width} = useResponsiveDimensions();

  const [targetList, setTargetList] = React.useState<
    (HomeDigitDetail & {sellAmount: string})[][]
  >([]);

  React.useEffect(() => {
    const digitlist = list.map(v => ({
      ...v,
      sellAmount: toPriceStr(v.sellAmount as number),
      winAmount: v.winAmount,
      pickBackImg: v.pickLogo,
      result: v.lastResult ? v.lastResult.split('') : ['-', '-', '-'],
    }));

    const saveList = list
      .filter(item => !item.cycle)
      .map(item => {
        return {
          label: item.pickName,
          value: item.id,
        };
      });
    saveDigitType(saveList);
    if (digitlist.length * 140 > width * 1.5) {
      const halfNum = Math.ceil(digitlist.length / 2);
      const arr1 = digitlist;
      const arr2 = digitlist.splice(halfNum);
      setTargetList(arr1.map((v, i) => [v, arr2[i]]));
    } else {
      setTargetList(digitlist.map(v => [v]));
    }
  }, [list, width]);

  return (
    <HomeFloorBox
      title={i18n.t('home.digit.title')}
      list={targetList}
      color="#D9DFED"
      renderItem={(arr: SafeAny[], index) => {
        return (
          <View key={index}>
            {arr?.map((item, itemIndex) => {
              return item === null || item === undefined ? (
                <View key={index + '' + itemIndex} />
              ) : (
                <Item
                  item={item}
                  key={index + '' + itemIndex}
                  itemIndex={itemIndex}
                  index={index}
                  serverTime={serverTime}
                  currentTime={currentTime}
                />
              );
            })}
          </View>
        );
      }}
    />
  );
};

const Item = ({
  itemIndex,
  index,
  item,
  serverTime,
  currentTime,
}: {
  item: DigitListItem;
  index: number;
  itemIndex: number;
  serverTime?: number;
  currentTime?: number;
}) => {
  const {width} = useResponsiveDimensions();
  const i18n = useTranslation();
  const calc = width / 375;
  const imageSize = theme.imageSize.m * calc;
  const logoSize = 56 * calc;

  const remain = React.useMemo(() => {
    if (serverTime) {
      return item.cycle
        ? getTimer(serverTime, item.cycle)
        : Math.floor(item.drawTime / 1000);
    }
  }, [item.cycle, serverTime, item.drawTime]);

  return (
    <NativeTouchableOpacity
      style={[
        {
          // height: (140 / 375) * width,
          width: (159 / 375) * width,
        },
        theme.background.palegrey,
        theme.borderRadius.m,
        theme.overflow.hidden,
        theme.position.rel,
        itemIndex !== 0 && theme.margin.topxs,
        index !== 0 && theme.margin.leftxs,
      ]}
      disabled={!item}
      onPress={() => {
        if (!serverTime) {
          return globalStore.globalWaringTotal(i18n.t('warning.initializin'));
        }
        if (item.drawTime <= 0 && !item.cycle) {
          globalStore.globalWaringTotal(
            i18n.t('home.tip.closed', {name: `${item.pickName}`}),
          );
          return;
        }
        goTo('Digit', {
          id: item.id,
          pickName: item.pickName,
          pickGameType: item.cycle ? 'quick' : 'normal',
          cycle: item.cycle,
        });
      }}
      key={index + '' + itemIndex}>
      <View
        style={[
          // eslint-disable-next-line react-native/no-inline-styles
          {
            height: 106,
            width: 159 * calc,
            backgroundColor: '#45B096',
          },
        ]}>
        <View
          style={[
            theme.flex.flex1,
            theme.flex.between,
            theme.padding.lrs,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              paddingVertical: 13,
            },
          ]}>
          <View
            style={[
              theme.flex.row,
              theme.flex.centerByCol,
              theme.flex.between,
            ]}>
            <View>
              <Text calc blod color={theme.basicColor.white} fontSize={10}>
                {item.pickName}
              </Text>
              <Text
                calc
                style={[
                  // eslint-disable-next-line react-native/no-inline-styles
                  {
                    fontStyle: 'italic',
                    lineHeight: globalStore.isWeb ? 12 : 12,
                    marginRight: 2,
                    height: 12,
                  },
                ]}
                textAlign="left"
                fontSize={9}
                fontFamily="fontDinBold"
                color={'#FFE939'}>
                {'WIN PRIZE'}
              </Text>
              <Price
                textProps={{
                  calc: true,
                  size: 'large',
                  color: theme.basicColor.white,
                }}
                price={+item.winAmount}
                fixed={0}
              />
            </View>
            <View>
              <View
                style={[
                  theme.position.abs,
                  theme.margin.leftxxl,
                  theme.fill.fill,
                  theme.margin.topl,
                  // eslint-disable-next-line react-native/no-inline-styles
                  {
                    top: 0,
                    bottom: (28 / 375) * width,
                  },
                  theme.flex.center,
                  theme.padding.tops,
                ]}>
                <Image
                  source={require('@assets/imgs/home/lottery-img-light-bg.webp')}
                  style={[
                    {
                      width: (104 / 200) * width,
                      height: (137 / 300) * width,
                    },
                  ]}
                />
              </View>
              <View style={[]}>
                <LazyImage
                  width={logoSize}
                  height={logoSize}
                  resizeMode="cover"
                  imageUrl={item.pickBackImg}
                />
              </View>
            </View>
          </View>
          {/* {serverTime && item.drawTime > 0 && (
            <View
              style={[
                theme.padding.tbxxs,
                theme.flex.row,
                theme.flex.centerByCol,
              ]}>
              <ClockIcon />
              <Text
                calc
                fontSize={12}
                fontFamily="fontDin"
                fontWeight="500"
                color={theme.basicColor.white}>
                {formatDate(serverTime + item.drawTime, '  Mon dd hh:mm')}
              </Text>
            </View>
          )} */}
          {serverTime && (item.drawTime > 0 || item.cycle) && (
            <View
              style={[
                theme.margin.tops,
                theme.flex.row,
                theme.flex.between,
                theme.flex.centerByCol,
              ]}>
              <Text calc fontSize={9} color={theme.basicColor.white}>
                {item.cycle
                  ? i18n.t('home.car.text')
                  : dayjs(serverTime + item.drawTime * 1000).format(
                      'MMM DD hh:mmA',
                    )}
              </Text>
              <CountDown
                resetTime={item.cycle ? item.cycle * 60 : 0}
                serverTime={serverTime}
                remain={remain}
                currentTime={currentTime}
              />
            </View>
          )}
        </View>

        {!item.cycle && item.drawTime <= 0 && (
          <View
            style={[
              theme.fill.fill,
              theme.position.abs,
              {
                backgroundColor: theme.basicColor.white + 'fff49',
              },
            ]}>
            <Image
              source={require('@assets/icons/home/closed-gray.webp')}
              style={[
                theme.image.m,
                theme.position.abs,
                {
                  right: theme.paddingSize.s,
                  top: theme.paddingSize.s,
                },
              ]}
            />
          </View>
        )}
      </View>
      <View
        style={[
          theme.flex.flex1,
          theme.flex.row,
          theme.padding.tbxxs,
          theme.flex.centerByCol,
          theme.flex.between,
          theme.padding.lrs,
          theme.background.white,
        ]}>
        <View>
          <Text
            accent
            style={[
              // eslint-disable-next-line react-native/no-inline-styles
              {
                lineHeight: 12,
              },
            ]}
            fontSize={10}>
            {i18n.t('home.digit.price')}:
          </Text>
          <Text
            blod
            color={theme.basicColor.dark}
            style={
              // eslint-disable-next-line react-native/no-inline-styles
              {
                lineHeight: 15,
              }
            }>
            {item.sellAmount}
          </Text>
        </View>
        <View
          style={[
            theme.padding.lrl,
            theme.borderRadius.xl,
            theme.border.secAccent,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              borderWidth: 0.5,
              paddingVertical: 3.5,
            },
          ]}>
          <Text blod style={[{lineHeight: theme.fontSize.m}]}>
            Play
          </Text>
        </View>
      </View>
      {item.isClosed && (
        <View
          style={[
            theme.fill.fill,
            theme.position.abs,
            {
              backgroundColor: theme.basicColor.white + 'fff49',
            },
          ]}>
          <Image
            source={require('@assets/icons/home/closed-gray.webp')}
            style={[
              theme.position.abs,
              {
                right: theme.paddingSize.s,
                top: theme.paddingSize.s,
                width: imageSize,
                height: imageSize,
              },
            ]}
          />
        </View>
      )}
    </NativeTouchableOpacity>
  );
};

export default React.memo(HomeLotteryDigit);
