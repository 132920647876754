import Text from '@/components/basic/text';
import theme from '@/style';
import React, {useEffect} from 'react';
import {View} from 'react-native';
import {BasicObject, NavigatorScreenProps, SVGProps} from '@/types';
import globalStore from '@/services/global.state';
import {useTranslation} from 'react-i18next';
import {
  BottomTabNavigationOptions,
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs';
import Home from './pages/home';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {Shadow} from 'react-native-shadow-2';
import Me from './pages/me';
import Promotion from './common-pages/promotion';
import {HomeSvg, PromotionsSvg, ReferralSvg, MeSvg} from '@components/svgs';
import Invitation from './common-pages/invitation';

const Tab = createBottomTabNavigator();

import IMWebView from './common-pages/im/im-webview';
import IMChat from './components/svgs/basic/im-chat';

const shadow = {
  startColor: 'rgba(0,0,0,0.05)',
  distance: 4,
  offset: [-1, 0],
  style: [
    theme.flex.row,
    theme.flex.around,
    theme.background.white,
    theme.fill.fillW,
    {
      height: 50,
    },
  ] as BasicObject[],
} as BasicObject;

if (globalStore.isWeb) {
  shadow.distance = 0;
  delete shadow.offset;
  shadow.style.push({
    boxShadow: '0 -1px 4px 0px rgba(0,0,0,0.05)',
  });
}

export const mainPageList: {
  name: string;
  link: string;
  component: (props: NavigatorScreenProps) => React.JSX.Element;
  icon?: ({color}: SVGProps) => React.JSX.Element;
  unmountOnBlur?: boolean;
  lazy?: boolean;
  svgIcon?: ({active}: {active: boolean}) => React.ReactNode;
}[] = [
  {
    name: 'Home',
    link: 'index/home',
    component: Home,
    icon: HomeSvg,
  },
  {
    name: 'Promotions',
    link: 'index/promotion',
    component: Promotion,
    icon: PromotionsSvg,
  },
  {
    name: 'Invite',
    link: 'index/referral',
    component: Invitation,
    icon: ReferralSvg,
  },
  {
    name: 'Chat',
    link: 'index/chat',
    component: IMWebView,
    svgIcon: ({active}) => <IMChat active={active} width={24} height={24} />,
    lazy: false,
  },
  {
    name: 'Me',
    link: 'index/me',
    component: Me,
    icon: MeSvg,
  },
];

const CusDefautTab = ({
  index,
  label,
  isFocused,
  options,
  onPress,
  count,
}: {
  index: number;
  label: string;
  isFocused: boolean;
  options: BottomTabNavigationOptions;
  onPress: (isFocused: boolean) => void;
  count?: number;
}) => {
  const Icon = mainPageList[index].icon;
  const Color = isFocused ? theme.basicColor.primary : theme.fontColor.second;
  return (
    <NativeTouchableOpacity
      activeOpacity={0.8}
      accessibilityRole="button"
      accessibilityState={isFocused ? {selected: true} : {}}
      accessibilityLabel={options.tabBarAccessibilityLabel}
      testID={options.tabBarTestID}
      onPress={() => onPress(isFocused)}
      style={[
        theme.flex.center,
        theme.flex.flex1,
        theme.position.rel,
        theme.overflow.visible,
      ]}>
      <View style={[theme.position.rel]}>
        {label === 'Invite' ? (
          <View style={[theme.icon.l, theme.position.rel]}>
            <View
              style={[
                theme.position.abs,
                theme.flex.center,
                theme.background.white,
                // eslint-disable-next-line react-native/no-inline-styles
                {
                  height: 48,
                  width: 48,
                  top: -24,
                  left: -12,
                  borderRadius: 24,
                },
              ]}>
              {!!Icon && <Icon color={theme.basicColor.primary} />}
            </View>
          </View>
        ) : mainPageList[index].svgIcon ? (
          mainPageList[index].svgIcon!({active: isFocused})
        ) : (
          !!Icon && <Icon color={Color} />
        )}
        {!!count && count > 0 && (
          <View
            style={[
              theme.flex.center,
              theme.position.abs,
              {
                paddingHorizontal: 5,
                paddingVertical: 2,
                borderWidth: 1,
                borderColor: '#FFF',
                borderRadius: 9999,
                backgroundColor: '#DC083B',
                left: 15,
                top: -4,
              },
            ]}>
            <Text fontSize={10} fontFamily="fontDin" blod color={'#FFF'}>
              {count > 99 ? '99+' : count}
            </Text>
          </View>
        )}
      </View>

      <Text
        blod
        fontSize={10}
        style={{
          color: isFocused ? theme.basicColor.primary : theme.fontColor.second,
        }}>
        {label}
      </Text>
    </NativeTouchableOpacity>
  );
};

const MainNav = () => {
  const {i18n} = useTranslation();
  const [imCount, setImCount] = React.useState<number>(0);
  useEffect(() => {
    const countStr = localStorage.getItem('imCount') || '0';
    setImCount(Number(countStr));
    const sub = globalStore.chatNumSubject.subscribe(count => {
      console.log('getCount', count, countStr);
      setImCount(count);
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);

  return (
    <Tab.Navigator
      // eslint-disable-next-line react/no-unstable-nested-components
      tabBar={props => {
        return (
          <Shadow containerViewProps={{id: 'tabbar-container'}} {...shadow}>
            {props.state?.routes.map((route, index) => {
              const {options} = props.descriptors[route.key];
              const label =
                options.tabBarLabel !== undefined
                  ? (options.tabBarLabel as string)
                  : options.title !== undefined
                  ? options.title
                  : route.name;
              const onPress = (isFocused: boolean) => {
                if (isFocused) {
                  return;
                }
                props.navigation.navigate(route.name);
              };
              return (
                <CusDefautTab
                  key={label}
                  index={index}
                  label={label}
                  isFocused={props.state.index === index}
                  options={options}
                  onPress={onPress}
                  count={label === 'Chat' ? imCount : 0}
                />
              );
            })}
          </Shadow>
        );
      }}
      screenOptions={{
        headerShown: false,
      }}>
      {mainPageList.map(v => (
        <Tab.Screen
          key={v.name}
          name={v.name}
          component={v.component}
          options={{
            title: i18n.t(`home.tab.${v.name.toLocaleLowerCase()}`),
            unmountOnBlur: v.unmountOnBlur,
            lazy: v.lazy,
          }}
        />
      ))}
    </Tab.Navigator>
  );
};

export default MainNav;
